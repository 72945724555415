import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk'
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'

import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')

export const UNISWAP_NFT_AIRDROP_CLAIM_ADDRESS = '0x8B799381ac40b838BBA4131ffB26197C432AFe78'

// celo v3 addresses
const CELO_V3_CORE_FACTORY_ADDRESSES = '0xAfE208a311B21f13EF87E33A90049fC17A7acDEc'
const CELO_ROUTER_ADDRESS = '0x5615CDAb10dc425a742d643d949a7F474C01abc4'
const CELO_V3_MIGRATOR_ADDRESSES = '0x3cFd4d48EDfDCC53D3f173F596f621064614C582'
const CELO_MULTICALL_ADDRESS = '0x633987602DE5C4F337e3DbF265303A1080324204'
const CELO_QUOTER_ADDRESSES = '0x82825d0554fA07f7FC52Ab63c961F330fdEFa8E8'
const CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x3d79EdAaBC0EaB6F08ED885C05Fc0B014290D95A'
const CELO_TICK_LENS_ADDRESSES = '0x5f115D9113F88e0a0Db1b5033D90D4a9690AcD3D'

// SAMI: addresses for hydra v3 contracts and v2 contracts
const HYDRACHAIN_V2_CORE_FACTORY_ADDRESSES = '0x2eF24A912d0bFD6148A1a291C958266a4aB132e9'
const HYDRACHAIN_V2_CORE_FACTORY_ADDRESSES_TESTNET = '0x2eF24A912d0bFD6148A1a291C958266a4aB132e9'
const HYDRACHAIN_V2_ROUTER_ADDRESSES = '0xfaC8015d556479f6e61cD35D54a4EF8939e3FD56'
const HYDRACHAIN_V2_ROUTER_ADDRESSES_TESTNET = '0xfaC8015d556479f6e61cD35D54a4EF8939e3FD56'

const HYDRACHAIN_V3_CORE_FACTORY_ADDRESSES = '0x3887e4C46Bb809d0345dC1d8f0E4e783424ACEB2'
const HYDRACHAIN_V3_CORE_FACTORY_ADDRESSES_TESTNET = '0x3887e4C46Bb809d0345dC1d8f0E4e783424ACEB2'
const HYDRACHAIN_MULTICALL_ADDRESS = '0xdbF3F5164A41FAbD326A6f5F74FC53ece7869f39'
const HYDRACHAIN_MULTICALL_ADDRESS_TESTNET = '0xdbF3F5164A41FAbD326A6f5F74FC53ece7869f39'
const HYDRACHAIN_TICK_LENS_ADDRESSES = '0x870df4B8FCC2EaC7ad5cdaF0B89d5e1f99FD5945'
const HYDRACHAIN_TICK_LENS_ADDRESSES_TESTNET = '0x870df4B8FCC2EaC7ad5cdaF0B89d5e1f99FD5945'

const HYDRACHAIN_SWAP_ROUTER_ADDRESSES = '0x2F5d924135b489B68070009Daf3035b6c262F49c' // Router 02 from swap-router-contracts
const HYDRACHAIN_SWAP_ROUTER_ADDRESSES_TESTNET = '0x2F5d924135b489B68070009Daf3035b6c262F49c' // Router 02 from swap-router-contracts
const HYDRACHAIN_V3_MIGRATOR_ADDRESSES = '0x11C87c74b5bd628C268e5a34C72C805Aa2160c2F'
const HYDRACHAIN_V3_MIGRATOR_ADDRESSES_TESTNET = '0x11C87c74b5bd628C268e5a34C72C805Aa2160c2F'
const HYDRACHAIN_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x7ec662149D821Cc631fa23fae70E4e514CdfB1dB'
const HYDRACHAIN_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES_TESTNET = '0x7ec662149D821Cc631fa23fae70E4e514CdfB1dB'
const HYDRACHAIN_QUOTER_ADDRESSES = '0xC711e153bc6310AC8C1273Da326d83E73066bA19'
const HYDRACHAIN_QUOTER_ADDRESSES_TESTNET = '0xC711e153bc6310AC8C1273Da326d83E73066bA19'

// eslint-disable-next-line import/no-unused-modules
export const V2_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V2_FACTORY_ADDRESS, [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
    SupportedChainId.CELO,
    SupportedChainId.CELO_ALFAJORES,
  ]),
  [SupportedChainId.MAINNET]: HYDRACHAIN_V2_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.TESTNET]: HYDRACHAIN_V2_CORE_FACTORY_ADDRESSES_TESTNET,
}

// eslint-disable-next-line import/no-unused-modules
export const V2_ROUTER_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
    SupportedChainId.CELO,
    SupportedChainId.CELO_ALFAJORES,
  ]),
  [SupportedChainId.MAINNET]: HYDRACHAIN_V2_ROUTER_ADDRESSES,
  [SupportedChainId.TESTNET]: HYDRACHAIN_V2_ROUTER_ADDRESSES_TESTNET,
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V3_FACTORY_ADDRESS, [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.MAINNET]: HYDRACHAIN_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.TESTNET]: HYDRACHAIN_V3_CORE_FACTORY_ADDRESSES_TESTNET,
  [SupportedChainId.CELO]: CELO_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_CORE_FACTORY_ADDRESSES,
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.MAINNET]: HYDRACHAIN_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.TESTNET]: HYDRACHAIN_V3_MIGRATOR_ADDRESSES_TESTNET,
  [SupportedChainId.CELO]: CELO_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_MIGRATOR_ADDRESSES,
}

export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', [
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.OPTIMISM,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.ARBITRUM_ONE]: '0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xa501c031958F579dB7676fF1CE78AD305794d579',
  [SupportedChainId.MAINNET]: HYDRACHAIN_MULTICALL_ADDRESS,
  [SupportedChainId.TESTNET]: HYDRACHAIN_MULTICALL_ADDRESS_TESTNET,
  [SupportedChainId.CELO]: CELO_MULTICALL_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_MULTICALL_ADDRESS,
}

export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON,
    SupportedChainId.POLYGON_MUMBAI,
  ]),
  [SupportedChainId.MAINNET]: HYDRACHAIN_SWAP_ROUTER_ADDRESSES,
  [SupportedChainId.TESTNET]: HYDRACHAIN_SWAP_ROUTER_ADDRESSES_TESTNET,
  [SupportedChainId.CELO]: CELO_ROUTER_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_ROUTER_ADDRESS,
}

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6', // VITO: Update this address
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3', // VITO: Update this address
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e', // VITO: Update this address
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8', // VITO: Update this address
}

export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.MAINNET]: HYDRACHAIN_QUOTER_ADDRESSES,
  [SupportedChainId.TESTNET]: HYDRACHAIN_QUOTER_ADDRESSES_TESTNET,
  [SupportedChainId.CELO]: CELO_QUOTER_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_QUOTER_ADDRESSES,
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xC36442b4a4522E871399CD717aBDD847Ab11FE88', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISM_GOERLI,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.MAINNET]: HYDRACHAIN_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.TESTNET]: HYDRACHAIN_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES_TESTNET,
  [SupportedChainId.CELO]: CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e', // VITO: Update this address
  [SupportedChainId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd', // VITO: Update this address
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.MAINNET]: HYDRACHAIN_TICK_LENS_ADDRESSES,
  [SupportedChainId.TESTNET]: HYDRACHAIN_TICK_LENS_ADDRESSES_TESTNET,
  [SupportedChainId.CELO]: CELO_TICK_LENS_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_TICK_LENS_ADDRESSES,
}
